function inputFileName() {

  function getName(fileInput) {
    var fileobj = fileInput.files;

    for (var i = 0; i < fileobj.length; i++) {
      document.querySelectorAll('.label-attach-field')[0].innerHTML = fileobj[i].name;
    }
  }

  document.getElementById('attach-field').onchange = function(){
    getName(this);
  }
}

export default inputFileName;