function menuMobile() {
  var menuEl        = document.querySelectorAll('.mobile__nav-toggle')[0];
  var cumploDown    = document.querySelectorAll('.cont-down')[0];
  var cumploHtml    = document.documentElement;
  var cumploHeader  = document.querySelectorAll('.cumplo-header')[0];
  
  menuEl.addEventListener('click', function(e){
    e.preventDefault();
    this.classList.toggle('open');
    cumploDown.classList.toggle('open');
    cumploHtml.classList.toggle('body--no-scroll');
    cumploHeader.classList.toggle('active');
  });
}

export default menuMobile;