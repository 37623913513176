function googleMap() {
  
	var map;
  var myLatLng = {lat: 19.438560, lng: -99.180270};
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 15,
    center: myLatLng
  });

  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'Cumplo'
  });

}

export default googleMap;